import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { AfbComponent } from "./afb/afb.component"

@NgModule({
  declarations: [AfbComponent],
  exports: [AfbComponent],
  imports: [CommonModule],
})
export class AfbModule {}
