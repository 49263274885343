import { Injectable } from "@angular/core"
import { Observable, of } from "rxjs"

@Injectable({
  providedIn: "root",
})
export class PictureService {
  private pictures = ["schlosshof_oben", "schlosshof", "sportwelt"]
  constructor() {
    this.shuffleArray(this.pictures)
  }

  getPictures(): Observable<string[]> {
    return of(this.pictures)
  }

  private shuffleArray(array: any[]) {
    let m = array.length
    let t: number
    let i: number

    // While there remain elements to shuffle
    while (m) {
      // Pick a remaining element…
      i = Math.floor(Math.random() * m--)

      // And swap it with the current element.
      t = array[m]
      array[m] = array[i]
      array[i] = t
    }

    return array
  }
}
