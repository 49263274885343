<nav
  id="navbar-main"
  class="navbar navbar-horizontal navbar-main navbar-expand-lg bg-gradient-default navbar-dark fixed-top"
  style="z-index: 100"
>
  <div class="container">
    <a class="navbar-brand text-white" (click)="goBack()"> Jugendfreizeit 2020 </a>

    <ul class="navbar-nav mx-auto">
      <li class="nav-item">
        <a class="nav-link" (click)="goBack()">
          <i class="fas fa-arrow-left"></i>
          Zurück
        </a>
      </li>
    </ul>
  </div>
</nav>
<div class="mb-5 mt-5" id="top"></div>
