import { Component, OnInit } from "@angular/core"
import { PictureService } from "../shared/picture.service"

@Component({
  selector: "app-carousel",
  templateUrl: "./carousel.component.html",
  styleUrls: ["./carousel.component.scss"],
})
export class CarouselComponent implements OnInit {
  itemsPerSlide = 1
  singleSlideOffset = true
  noWrap = false

  pictures: string[]
  constructor(private pictureService: PictureService) {}

  ngOnInit() {
    this.pictureService.getPictures().subscribe((pictures) => (this.pictures = pictures))
  }
}
