import { Component, OnInit } from "@angular/core"
import { interval } from "rxjs"
import { CountdownService } from "src/app/shared/countdown.service"

@Component({
  selector: "app-countdown",
  templateUrl: "./countdown.component.html",
  styleUrls: ["./countdown.component.scss"],
})
export class CountdownComponent implements OnInit {
  timeLeft: Time
  constructor(private countDownService: CountdownService) {}

  ngOnInit() {
    this.timeLeft = this.countDownService.timeLeft
  }

  get2Digits(value: number): string {
    const valueString = value.toString()
    if (valueString.length < 2) {
      return "0" + valueString
    }
    return valueString
  }
}
