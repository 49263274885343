import { ApplicationRef, Injectable, RendererFactory2 } from "@angular/core"
import { NavigationEnd, NavigationStart, Router } from "@angular/router"
import { of } from "rxjs"
import { delayWhen } from "rxjs/operators"
import stringify from "safe-stable-stringify"
import { environment } from "src/environments/environment"

import { MATOMO_DOWNLOAD_CLASSNAME, MatomoService } from "./matomo.service"
import { TrackingConfig } from "./types"
import { UserAgentService } from "./useragent.service"

@Injectable({ providedIn: "root" })
export class TrackingService {
  private readonly renderer = this.rendererFactory.createRenderer(null, null)

  private referrer: string
  private matomoLoaded: boolean

  constructor(
    private readonly appRef: ApplicationRef,
    private readonly config: TrackingConfig,
    private readonly matomo: MatomoService,
    private readonly rendererFactory: RendererFactory2,
    private readonly router: Router,
    private readonly userAgent: UserAgentService,
  ) {
    if (this.isDebuggingEnabled) {
      this.initDebugging()
    }
    if (this.isTrackingEnabled) {
      this.initNavigationTracking()
      this.loadMatomoOnceDeferred()
    }
  }

  private get isDebuggingEnabled() {
    return !environment.production && false
  }

  private get isTrackingEnabled() {
    return this.config.matomoEnabled
  }

  private loadMatomoOnceDeferred() {
    if (this.matomoLoaded) {
      return
    }
    if (!this.isTrackingEnabled) {
      return
    }
    this.matomo.load()
    this.matomoLoaded = true
  }

  // https://developer.matomo.org/guides/tracking-javascript-guide#internal-search-tracking
  trackSearch(search: string, category: string | false, numberOfResults: number | false) {
    if (this.isTrackingEnabled) {
      this.loadMatomoOnceDeferred()

      this.matomo.trackSiteSearch(search || "", category || false, numberOfResults)
    }
  }

  // https://developer.matomo.org/guides/spa-tracking
  trackPageView(name: string, url: string, referrer: string) {
    if (this.isTrackingEnabled) {
      this.loadMatomoOnceDeferred()

      this.matomo.setCustomUrl(url)
      this.matomo.setReferrerUrl(referrer)
      this.matomo.trackPageView(name)
    }
  }

  // https://matomo.org/docs/event-tracking/
  trackEvent(category: "Meta" | "Conversion" | "Usage", action: string, name?: string, value?: any) {
    if (this.isTrackingEnabled) {
      this.loadMatomoOnceDeferred()

      this.matomo.trackEvent(category, action, name, value)
    }
  }

  // https://developer.matomo.org/guides/spa-tracking#making-matomo-aware-of-new-content
  scanPageForTaggedContent() {
    if (this.isTrackingEnabled) {
      setTimeout(() => {
        const node = document.querySelector("app-root")
        this.matomo.trackVisibleContentImpressions(true, 1000)
        this.matomo.trackContentImpressionsWithinNode(node)
        // this.matomo.logAllContentBlocksOnPage()
      }, 0)
    }
  }

  // https://developer.matomo.org/guides/content-tracking#tagging-content
  tagRegion(el: HTMLElement, name: string) {
    if (this.isDebuggingEnabled) {
      console.debug("🎯 tagged region", stringify(name), { el })
      this.debugTaggedContent({ el, name, color: "turquoise" })
    }

    if (this.isTrackingEnabled) {
      this.loadMatomoOnceDeferred()

      this.renderer.setAttribute(el, "data-track-content", "")
      this.renderer.setAttribute(el, "data-content-name", name)
    }
  }

  // https://developer.matomo.org/guides/content-tracking#tagging-content
  tagElement(el: HTMLElement, name: string) {
    if (this.isDebuggingEnabled) {
      console.debug("🎯 tagged element", stringify(name), { el })
      this.debugTaggedContent({ el, name, color: "royalblue" })
    }

    if (this.isTrackingEnabled) {
      this.loadMatomoOnceDeferred()

      this.renderer.setAttribute(el, "data-content-piece", name)
      // this.renderer.setAttribute(el, "data-content-target", "")
      // if (data.ignoreClicks) {
      //   this.renderer.setAttribute(el, "data-content-ignoreinteraction", "")
      // }
    }
  }

  // https://developer.matomo.org/guides/tracking-javascript-guide#recording-a-click-as-a-download
  tagDownloadLink(el: HTMLElement, name: string) {
    if (this.isDebuggingEnabled) {
      console.debug("🎯 tagged download", stringify(name), { el })
      this.debugTaggedContent({ el, name, color: "violet" })
    }

    if (this.isTrackingEnabled) {
      this.loadMatomoOnceDeferred()

      this.renderer.addClass(el, MATOMO_DOWNLOAD_CLASSNAME)
    }
  }

  private initNavigationTracking() {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationStart) {
        this.referrer = window.location.pathname
      }
      if (e instanceof NavigationEnd) {
        const url = window.location.pathname

        of(true)
          .pipe(delayWhen(() => this.appRef.isStable))
          .subscribe(() => {
            this.trackPageView(url, url, this.referrer)
            this.scanPageForTaggedContent()
          })
      }
    })
  }

  private initDebugging() {
    if (!this.isDebuggingEnabled) {
      return
    }

    const trackingDebugStyleEl = document.createElement("style")
    trackingDebugStyleEl.innerHTML = `
        .tracked::before {
          content: attr(data-tracking-name);
          position: absolute;
          z-index: 99999;
          top: 0.125rem;
          left: 0.125rem;
          font-weight: normal;
          font-size: 1.25rem;
          line-height: 1;
          background: wheat;
          color: attr(data-tracking-color);
          padding: 0.125rem 0.25rem;
          white-space: nowrap;
          text-transform: none;
          color: black;
          user-select: none;
        }
        .tracked:hover {
          transform: z-index: 99999;
        }
      `
    document.body.appendChild(trackingDebugStyleEl)
  }

  private debugTaggedContent({ el, name, color }: { el: HTMLElement; name: string; color: string }) {
    if (!this.isDebuggingEnabled) {
      return
    }

    const style = window.getComputedStyle(el)
    const position = !style.position || style.position === "static" ? "relative" : style.position

    el.style.position = position
    el.style.zIndex = String(+style.zIndex || 0 + 1)
    el.style.boxShadow = "inset 0 0 0 0.125rem " + color
    el.classList.add("tracked")

    if (name) {
      el.setAttribute("data-tracking-name", name)
    }

    if (color) {
      el.setAttribute("data-tracking-color", color)
    }
  }
}
