import { Component, OnInit } from "@angular/core"

import { PricingService } from "../shared/pricing.service"

@Component({
  selector: "app-pricing",
  templateUrl: "./pricing.component.html",
})
export class PricingComponent implements OnInit {
  earlyBird = false

  pricing: Pricing[]

  constructor(private pricingService: PricingService) {}

  ngOnInit() {
    this.pricingService.getPricing().subscribe((p) => (this.pricing = p))
  }
}
