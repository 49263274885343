<div class="row pt-5 pb-5" style="background-color: #dee2e6">
  <div class="col-md-8 justify-content-center mx-auto">
    <h3 class="display-1 text-center">Karte</h3>
  </div>
  <div class="col-md-12 justify-content-center mx-auto row">
    <iframe
      class="col-md-10"
      height="450"
      frameborder="0"
      style="border: 0"
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d8732.751828748475!2d13.184317!3d47.327308!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4776d80e3674becd%3A0x50ad8b3f8b7ae04!2sLiechtensteinklammstra%C3%9Fe%20142%2C%205600%20Plankenau!5e1!3m2!1sen!2sat!4v1651233465431!5m2!1sen!2sat"
      allowfullscreen
      loading="lazy"
    ></iframe>
  </div>
</div>
