import { ApplicationRef, ModuleWithProviders, NgModule, RendererFactory2 } from "@angular/core"
import { Router } from "@angular/router"

import { MatomoService } from "./matomo.service"
import { TrackDownloadDirective } from "./track-download.directive"
import { TrackElementDirective } from "./track-element.directive"
import { TrackRegionDirective } from "./track-region.directive"
import { TrackingService } from "./tracking.service"
import { TrackingConfig } from "./types"
import { UserAgentService } from "./useragent.service"

const directives = [TrackDownloadDirective, TrackElementDirective, TrackRegionDirective]

@NgModule({
  declarations: [...directives],
  exports: [...directives],
})
export class TrackingModule {
  static forRoot(config: TrackingConfig): ModuleWithProviders<TrackingModule> {
    return {
      ngModule: TrackingModule,
      providers: [
        { provide: TrackingConfig, useValue: config },
        {
          deps: [ApplicationRef, MatomoService, RendererFactory2, Router, UserAgentService],
          provide: TrackingService,
          useFactory: (
            appRef: ApplicationRef,
            matomo: MatomoService,
            rendererFactory: RendererFactory2,
            router: Router,
            userAgent: UserAgentService,
          ) => {
            return new TrackingService(appRef, config, matomo, rendererFactory, router, userAgent)
          },
        },
      ],
    }
  }
}
