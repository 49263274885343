<app-countdown *ngIf="countdownActive()"></app-countdown>
<div *ngIf="!countdownActive()">
  <progressbar
    class="fixed-top"
    type="default"
    [value]="percentage"
    style="width: 102%; height: 5px; margin-left: -1%"
  ></progressbar>
  <router-outlet></router-outlet>
  <button class="btn btn-primary btn-icon-only back-to-top show" name="button" type="button" (click)="scrollToTop()">
    <i class="fas fa-arrow-up"></i>
  </button>
</div>
