<div id="home">
  <div id="header">
    <div class="header-content">
      <div class="countdown" id="countdown">
        <div class="container">
          <div class="row">
            <h1 class="col-10 mx-auto justify-content-center text-white">Neugierig? Komm später wieder..</h1>
          </div>
          <div class="row">
            <div class="countdown-item col-sm-3 col-xs-3 mt-2">
              <div class="countdown-number" id="countdown-days">{{ get2Digits(timeLeft.days) }}</div>
              <div class="countdown-label">Tage</div>
            </div>

            <div class="countdown-item col-sm-3 col-xs-3 mt-2">
              <div class="countdown-number" id="countdown-hours">{{ get2Digits(timeLeft.hours) }}</div>
              <div class="countdown-label">Stunden</div>
            </div>

            <div class="countdown-item col-sm-3 col-xs-3 mt-2">
              <div class="countdown-number" id="countdown-minutes">{{ get2Digits(timeLeft.minutes) }}</div>
              <div class="countdown-label">Minuten</div>
            </div>

            <div class="countdown-item col-sm-3 col-xs-3 mt-2">
              <div class="countdown-number" id="countdown-seconds">{{ get2Digits(timeLeft.seconds) }}</div>
              <div class="countdown-label">Sekunden</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
