<div class="container pt-5 pb-5">
  <div class="row">
    <div class="col-md-6 mx-auto text-center mb-5">
      <h3 class="justify-content-center display-1">Preise</h3>
      <h3 class="display">
        Folgende Preise stehen zur Auswahl, abhängig davon, ob du Geld verdienst oder es jemand für dich verdient
        <!-- und Early Bird Bonus -->
      </h3>
    </div>
  </div>
  <!-- <div class="row" *ngIf="pricing">
    <div class="nav-wrapper ml-auto mr-3 mb-3">
      <button type="button" class="btn"
        [ngClass]="{ 'btn-warning' : !earlyBird, 'btn-success': earlyBird }" [(ngModel)]="earlyBird"
        btnCheckbox>
        Preise mit Early Bird zeigen
      </button>
    </div>
  </div> -->
  <div class="row">
    <app-pricing-card
      class="col-md-{{ 12 / pricing.length > 4 ? 12 / pricing.length : 4 }}"
      *ngFor="let card of pricing"
      [pricingCard]="card"
      [earlyBird]="earlyBird"
    >
    </app-pricing-card>
  </div>
</div>
