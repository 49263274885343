import { HttpClientModule } from "@angular/common/http"
import { NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { BrowserModule } from "@angular/platform-browser"
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import { ButtonsModule } from "ngx-bootstrap/buttons"
import { CarouselModule } from "ngx-bootstrap/carousel"
import { CollapseModule } from "ngx-bootstrap/collapse"
import { BsDatepickerModule } from "ngx-bootstrap/datepicker"
import { BsDropdownModule } from "ngx-bootstrap/dropdown"
import { ModalModule } from "ngx-bootstrap/modal"
import { ProgressbarModule } from "ngx-bootstrap/progressbar"
import { NgxPageScrollCoreModule } from "ngx-page-scroll-core"
import { ToastrModule } from "ngx-toastr"
import { environment } from "src/environments/environment"

import { AfbPageComponent } from "./afb-page/afb-page.component"
import { AfbModule } from "./afb/afb.module"
import { AppRoutingModule } from "./app-routing.module"
import { AppComponent } from "./app.component"
import { CalltoactionComponent } from "./calltoaction/calltoaction.component"
import { CarouselComponent } from "./carousel/carousel.component"
import { CoreModule } from "./core/core.module"
import { CountdownModule } from "./countdown/countdown.module"
import { ImpressumComponent } from "./impressum/impressum.component"
import { InfosComponent } from "./infos/infos.component"
import { LandingComponent } from "./landing/landing.component"
import { MapComponent } from "./map/map.component"
import { OverviewComponent } from "./overview/overview.component"
import { PricingCardComponent } from "./pricing/pricing-card/pricing-card.component"
import { PricingComponent } from "./pricing/pricing.component"
import { TrackingModule } from "./tracking/tracking.module"

// Ngx Plugins
// Components
// Custom Modules
@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    OverviewComponent,
    CarouselComponent,
    PricingComponent,
    PricingCardComponent,
    InfosComponent,
    CalltoactionComponent,
    MapComponent,
    ImpressumComponent,
    AfbPageComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,

    // Plugins
    ToastrModule.forRoot(),
    CarouselModule.forRoot(),
    ButtonsModule.forRoot(),
    ModalModule.forRoot(),
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ProgressbarModule.forRoot(),
    NgxPageScrollCoreModule.forRoot({ duration: 350, scrollOffset: 60 }),
    TrackingModule.forRoot({
      matomoEnabled: true,
      matomoSiteID: 2,
      production: environment.production,
    }),

    // Custom Modules
    CoreModule,
    CountdownModule,
    AfbModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
