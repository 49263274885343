import { Component, ElementRef, HostListener, OnInit } from "@angular/core"

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styles: [
    `
      youtube-player {
        width: 100% !important;
        height: 100% !important;
      }
    `,
  ],
})
export class HeaderComponent implements OnInit {
  playerConfig = {
    controls: 0,
    mute: 1,
    autoplay: 1,
  }

  isCollapsed = true
  fixed = false

  navItems: NavItems[] = [
    { name: "Übersicht", href: "overview" },
    { name: "Weitere Infos", href: "infos" },
    // { name: "Preise", href: "pricing" },
    { name: "Karte", href: "map" },
    { name: "Bilder", href: "pictures" },
    { name: "Anmeldung", id: "eventbrite-widget-modal-trigger-330428409267" },
  ]

  @HostListener("window:scroll", [])
  checkScroll() {
    const scrollPosition = window.pageYOffset

    if (scrollPosition > window.innerHeight) {
      this.fixed = true
    } else {
      this.fixed = false
    }
  }
  constructor(public el: ElementRef) {}

  ngOnInit() {
    this.checkScroll()
    const tag = document.createElement("script")
    tag.src = "https://www.youtube.com/iframe_api"
    document.body.appendChild(tag)
  }
}
