import { Component, OnInit, AfterViewInit } from "@angular/core"
import { Router } from "@angular/router"

@Component({
  selector: "app-go-back-header",
  templateUrl: "./go-back-header.component.html",
})
export class GoBackHeaderComponent implements AfterViewInit {
  constructor(private router: Router) {}

  ngAfterViewInit() {
    this.scrollToTop()
  }

  goBack() {
    this.router.navigateByUrl("/")
    setTimeout(this.scrollToTop, 100)
  }

  scrollToTop() {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: "smooth",
    })
  }
}
