<div class="row pt-5 pb-5">
  <div class="col-md-8 justify-content-center mx-auto row">
    <h3 class="justify-content-center display-1">Bilder</h3>
  </div>
  <div class="col-md-10 mx-auto">
    <carousel
      [itemsPerSlide]="itemsPerSlide"
      [singleSlideOffset]="singleSlideOffset"
      [noWrap]="noWrap"
      class="rounded overflow-hidden transform-perspective-left"
    >
      <slide *ngFor="let picture of pictures">
        <img src="assets/img/austria/{{ picture }}.jpg" class="mx-auto" style="display: block; width: 100%" />
        <!-- <div class="carousel-caption">
          <h2 class="text-white">{{ picture | titlecase }}</h2>
        </div> -->
      </slide>
    </carousel>
  </div>
</div>
