import { Component, OnInit } from "@angular/core"

@Component({
  selector: "app-not-found",
  template: `
    <div class="page-header error-page">
      <div class="page-header-image" style="background-image: url('assets/img/background/404.svg');"></div>
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center">
            <h1 class="title">404</h1>
            <p class="lead" translate>Seite nicht gefunden</p>
            <h4 class="description text-default" translate>Ups. Sieht so aus als hättest du dich verlaufen.</h4>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class NotFoundComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
