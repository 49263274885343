import { AfterViewInit, Directive, ElementRef, Input, OnChanges } from "@angular/core"

import { TrackingService } from "./tracking.service"

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "header,footer,section,aside,nav",
})
export class TrackRegionDirective implements AfterViewInit, OnChanges {
  @Input() crLabel: string
  @Input() crIs: string
  @Input() crWhere: string

  private label: string
  private trackingLabel: string

  constructor(private readonly el: ElementRef, private readonly tracking: TrackingService) {}

  tagRegion() {
    const el: HTMLElement = this.el.nativeElement
    const label = [this.crIs, this.crLabel].filter(Boolean).join(": ")
    const trackingLabel = [label, this.crWhere && `(${this.crWhere})`].filter(Boolean).join(" ").trim()

    if (label === this.label && trackingLabel === this.trackingLabel) {
      return
    }
    this.label = label
    this.trackingLabel = trackingLabel

    if (label) {
      this.tracking.tagRegion(el, trackingLabel)
      el.setAttribute("aria-label", label)
      el.setAttribute("id", label.toLowerCase().replace(/\W+/, "-"))
    } else {
      console.debug("💥 Region has no label:", el)
    }
  }

  ngAfterViewInit() {
    this.tagRegion()
  }

  ngOnChanges() {
    this.tagRegion()
  }
}
