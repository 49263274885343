<div class="card card-pricing bg-gradient-{{ pricingCard.color }} border-0 text-center mb-4">
  <div class="card-header bg-transparent">
    <h2 class="text-uppercase ls-1 text-white py-3 mb-0">{{ pricingCard.title }}</h2>
    <p *ngIf="earlyBird" class="text-white">Early Bird</p>
  </div>
  <div class="card-body">
    <div class="display-2 text-white">{{ earlyBird === false ? pricingCard.cost : pricingCard.earlyBirdCost }} €</div>
    <span class="text-white">(Anzahlung: {{ pricingCard.initialCost }} €)</span>
    <ul class="list-unstyled my-2" *ngIf="included">
      <li *ngFor="let item of included">
        <div class="d-flex align-items-center">
          <div>
            <div class="icon icon-shape bg-white shadow rounded-circle text-{{ pricingCard.color }}">
              <i class="fas fa-check"></i>
            </div>
          </div>
          <div>
            <span class="pl-2 text-white">{{ item }}</span>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div class="card-footer bg-transparent">
    <a [routerLink]="'/registration/' + pricingCard.id">
      <h3 class="text-white">Melde dich jetzt an</h3>
    </a>
  </div>
</div>
