import { Injectable } from "@angular/core"
import isDnt from "is-dnt"
import parseUserAgent from "ismobilejs"

@Injectable({
  providedIn: "root",
})
export class UserAgentService {
  private readonly info = parseUserAgent(window.navigator)

  get isMobile() {
    return this.info.any
  }

  get isPhone() {
    return this.info.phone
  }

  get isTablet() {
    return this.info.tablet
  }

  get isSafari() {
    // @ts-ignore
    return typeof window.webkitConvertPointFromNodeToPage === "function"
  }

  get isDntEnabled() {
    if (typeof window.external === "undefined") {
      Object.assign(window, { external: {} })
    }
    return isDnt()
  }
}
