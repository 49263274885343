import { Component, OnInit } from "@angular/core"

@Component({
  selector: "app-afb-page",
  templateUrl: "./afb-page.component.html",
})
export class AfbPageComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
