import { AfterViewInit, Directive, ElementRef } from "@angular/core"

import { TrackingService } from "./tracking.service"

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "a[download]",
})
export class TrackDownloadDirective implements AfterViewInit {
  constructor(private readonly el: ElementRef, private readonly tracking: TrackingService) {}

  private ext: string

  ngAfterViewInit() {
    const el: HTMLElement = this.el.nativeElement
    const ext = el.getAttribute("href")?.split(".").pop().trim()

    if (ext === this.ext) {
      return
    }
    this.ext = ext

    if (ext) {
      const label = ["Download", ext].filter(Boolean).join(": ").trim()
      this.tracking.tagDownloadLink(el, label)
      el.setAttribute("aria-label", label)
    } else {
      console.debug("💥 Download has no extension:", el)
    }
  }
}
