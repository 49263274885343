import { Component, OnInit } from "@angular/core"

@Component({
  selector: "app-server-error",
  template: `
    <div class="page-header page-500">
      <div class="page-header-image" style="background-image: url('assets/img/background/500.svg');"></div>
      <div class="container low">
        <div class="row">
          <div class="col-md-12 text-center">
            <h4 class="display-4" translate>Server Error</h4>
            <p class="lead" translate>Hoppla. Das hätte eigentlich nicht passieren dürfen</p>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class ServerErrorComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
