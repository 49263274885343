import { Component, OnInit } from "@angular/core"
import { FeaturesService } from "../shared/features.service"

@Component({
  selector: "app-overview",
  templateUrl: "./overview.component.html",
})
export class OverviewComponent implements OnInit {
  shortOverview: OverviewShort

  constructor(private featureService: FeaturesService) {}

  ngOnInit() {
    this.featureService.getShortOverview().subscribe((shortOverview) => (this.shortOverview = shortOverview))
  }
}
