<div class="row pt-5 pb-5" style="background-color: #dee2e6">
  <div class="col-md-8 justify-content-center mx-auto row">
    <h3 class="justify-content-center display-1 mx-aut text-center col-12">Melde dich jetzt an!</h3>
    <a class="m-2 mx-auto">
      <button type="button" id="eventbrite-widget-modal-trigger-330428409267" class="btn btn-success btn-lg">
        Zur Anmeldung
      </button>
    </a>
    <!-- <h3 class="justify-content-center display-2 mx-auto text-center col-12">
      Anmeldeschluss
      {{ endOfRegistration | date: "dd.MM.yyyy" }}
    </h3> -->
  </div>
</div>
