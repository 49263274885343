import { Component, OnInit, Input, OnChanges, SimpleChanges } from "@angular/core"
import { PricingService } from "src/app/shared/pricing.service"

@Component({
  selector: "app-pricing-card",
  templateUrl: "./pricing-card.component.html",
})
export class PricingCardComponent implements OnChanges {
  @Input() pricingCard: Pricing
  @Input() earlyBird: boolean

  cost: number
  included: string[]

  constructor(private pricingService: PricingService) {}

  ngOnChanges(changes: SimpleChanges) {
    this.pricingService.getIncluded().subscribe((i) => (this.included = i))
    if (this.earlyBird) {
      this.cost = this.pricingCard.earlyBirdCost
    } else {
      this.cost = this.pricingCard.cost
    }
  }
}
