<header class="skew-separator">
  <div class="header-wrapper">
    <div class="page-header header-video">
      <div class="overlay"></div>
      <youtube-player videoId="n_mkUBEQ2uc" [playerVars]="playerConfig" width="100%" height="100%"></youtube-player>
      <div class="container text-center">
        <div class="row">
          <div class="col-lg-7 mx-auto">
            <h1 class="video-text">Österreich 2022</h1>
            <h1 class="display-3 text-white">Daniel 1-7</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<div [ngClass]="{ 'mb-5': fixed }" id="top"></div>

<nav
  id="navbar-main"
  class="navbar navbar-horizontal navbar-main navbar-expand-lg bg-gradient-default navbar-dark"
  style="z-index: 100"
  [ngClass]="{ 'fixed-top': fixed }"
>
  <div class="container">
    <a class="navbar-brand text-white" pageScroll pageScrollOffset="0" href="#top"> Österreich 2022 </a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbar-collapse"
      aria-expanded="false"
      aria-label="Toggle navigation"
      (click)="isCollapsed = !isCollapsed"
      [attr.aria-expanded]="!isCollapsed"
      aria-controls="collapseBasic"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="navbar-collapse navbar-custom-collapse collapse" id="collapseBasic" [collapse]="isCollapsed">
      <div class="navbar-collapse-header">
        <div class="row">
          <div class="col-6 collapse-brand">
            <a pageScroll pageScrollOffset="0" href="#top"> Österreich 2022 </a>
          </div>
          <div class="col-6 collapse-close">
            <button
              type="button"
              class="navbar-toggler"
              data-toggle="collapse"
              data-target="#navbar-collapse"
              aria-controls="navbar-collapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
              (click)="isCollapsed = !isCollapsed"
            >
              <span></span> <span></span>
            </button>
          </div>
        </div>
      </div>
      <ul class="navbar-nav mx-auto">
        <li class="nav-item" *ngFor="let navItem of navItems">
          <a *ngIf="navItem.href" class="nav-link" pageScroll [href]="'#' + navItem.href" (click)="isCollapsed = true">
            {{ navItem.name }}
          </a>
          <a *ngIf="navItem.id" class="nav-link" pageScroll [id]="navItem.id" (click)="isCollapsed = true">
            {{ navItem.name }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
