import { Injectable, NgZone } from "@angular/core"

import { TrackingConfig } from "./types"

declare const window: {
  _paq: any[]
}

export const MATOMO_DOWNLOAD_CLASSNAME = "matomo_download"
export const MATOMO_BASE_URL = "https://matomo.roser.dev"

/**
 * MATOMO API WRAPPER
 * https://developer.matomo.org/api-reference/tracking-javascript
 * https://developer.matomo.org/guides/tracking-javascript-guide
 */
@Injectable({ providedIn: "root" })
export class MatomoService {
  constructor(private readonly config: TrackingConfig, private readonly zone: NgZone) {}

  load() {
    console.log("📈 matomo loading...")

    this.setSiteId(this.config.matomoSiteID)
    this.setTrackerUrl(MATOMO_BASE_URL + "/matomo.php")
    this.disableCookies()
    this.setDoNotTrack(false)
    this.alwaysUseSendBeacon()
    this.enableHeartBeatTimer(15)
    this.enableLinkTracking()
    this.setLinkTrackingTimer(0)
    this.setDownloadClasses([MATOMO_DOWNLOAD_CLASSNAME])

    const script = document.createElement("script")
    script.async = true
    script.defer = true
    script.src = MATOMO_BASE_URL + "/matomo.js"

    document.head.appendChild(script)
  }

  private push(...args: any[]) {
    this.zone.runOutsideAngular(() => {
      console.debug("📈 matomo", args)
      window._paq = window._paq || []
      window._paq.push(args)
    })
  }

  //
  // USING THE TRACKER OBJECT
  //

  /**
   * Log a page view
   */
  trackPageView(customTitle?: string) {
    this.push("trackPageView", customTitle)
  }

  /**
   * Log an event with an event category (Videos, Music, Games...), an event
   * action (Play, Pause, Duration, Add Playlist, Downloaded, Clicked...), and
   * an optional event name and optional numeric value.
   */
  trackEvent(category: string, action: string, name?: string, value?: any) {
    this.push("trackEvent", category, action, name, value)
  }

  /**
   * Log an internal site search for a specific keyword, in an optional category,
   * specifying the optional count of search results in the page.
   */
  trackSiteSearch(keyword: string, category?: string | false, resultsCount?: number | false) {
    this.push("trackSiteSearch", keyword, category, resultsCount)
  }

  /**
   * Log a conversion for the numeric goal ID, with an optional numeric custom
   * revenue customRevenue.
   */
  trackGoal(idGoal: number, customRevenue?: number) {
    this.push("trackGoal", idGoal, customRevenue)
  }

  /**
   * Log a click from your own code. url is the full URL which is to be tracked
   * as a click. linkType can either be 'link' for an outlink or 'download' for a
   * download.
   */
  trackLink(url: string, linkType: "link" | "download") {
    this.push("trackLink", url, linkType)
  }

  /**
   * Scan the entire DOM for all content blocks and tracks all impressions once
   * the DOM ready event has been triggered.
   */
  trackAllContentImpressions() {
    this.push("trackAllContentImpressions")
  }

  /**
   * Scan the entire DOM for all content blocks as soon as the page is loaded.
   * It tracks an impression only if a content block is actually visible.
   */
  trackVisibleContentImpressions(checkOnScroll: boolean, timeIntervalInMs: number) {
    this.push("trackVisibleContentImpressions", checkOnScroll, timeIntervalInMs)
  }

  /**
   * Scan the given DOM node and its children for content blocks and tracks an
   * impression for them if no impression was already tracked for it.
   */
  trackContentImpressionsWithinNode(domNode: Element) {
    this.push("trackContentImpressionsWithinNode", domNode)
  }

  /**
   * Track an interaction with the given DOM node / content block.
   */
  trackContentInteractionNode(domNode: Element, contentInteraction: string) {
    this.push("trackContentInteractionNode", domNode, contentInteraction)
  }

  /**
   * Track a content impression using the specified values.
   */
  trackContentImpression(contentName: string, contentPiece: string, contentTarget: string) {
    this.push("trackContentImpression", contentName, contentPiece, contentTarget)
  }

  /**
   * Track a content interaction using the specified values.
   */
  trackContentInteraction(
    contentInteraction: string,
    contentName: string,
    contentPiece: string,
    contentTarget: string,
  ) {
    this.push("trackContentInteraction", contentInteraction, contentName, contentPiece, contentTarget)
  }

  /**
   * Log all found content blocks within a page to the console. This is useful to
   * debug / test content tracking.
   */
  logAllContentBlocksOnPage() {
    this.push("logAllContentBlocksOnPage")
  }

  /**
   * Send a ping request. Ping requests do not track new actions. If they are sent
   * within the standard visit length, they will extend the existing visit and the
   * current last action for the visit. If sent after the standard visit length,
   * ping requests will create a new visit using the last action in the last known
   * visit. See also enableHeartBeatTimer.
   */
  ping() {
    this.push("ping")
  }

  /**
   * Install a Heart beat timer that will regularly send requests to Matomo in
   * order to better measure the time spent on the page. These requests will be
   * sent only when the user is actively viewing the page (when the tab is active
   * and in focus). These requests will not track additional actions or pageviews.
   * By default, delayInSeconds is set to 15 seconds. See also ping and the
   * developer guide.
   */
  enableHeartBeatTimer(delayInSeconds: number) {
    this.push("enableHeartBeatTimer", delayInSeconds)
  }

  /**
   * Install link tracking on all applicable link elements. Set the enable
   * parameter to true to use pseudo click-handler (treat middle click and open
   * contextmenu as left click). A right click (or any click that opens the
   * context menu) on a link will be tracked as clicked even if "Open in new tab"
   * is not selected. If "false" (default), nothing will be tracked on open
   * context menu or middle click.
   */
  enableLinkTracking(enableContextMenuAndMiddleClickTracking?: boolean) {
    this.push("enableLinkTracking", enableContextMenuAndMiddleClickTracking)
  }

  /**
   * Enable cross domain linking. By default, the visitor ID that identifies a
   * unique visitor is stored in the browser's first party cookies. This means the
   * cookie can only be accessed by pages on the same domain. If you own multiple
   * domains and would like to track all the actions and pageviews of a specific
   * visitor into the same visit, you may enable cross domain linking (learn more).
   * Whenever a user clicks on a link it will append a URL parameter pk_vid to the
   * clicked URL which forwards the current visitor ID value to the page of the
   * different domain.
   */
  enableCrossDomainLinking() {
    this.push("enableCrossDomainLinking")
  }

  /**
   * Set the cross domain linking timeout (in seconds). By default, the two visits
   * across domains will be linked together when the link is clicked and the page
   * is loaded within a 180 seconds timeout window.`
   */
  setCrossDomainLinkingTimeout(timeout: number) {
    this.push("setCrossDomainLinkingTimeout", timeout)
  }

  /**
   * Get the query parameter to append to links to handle cross domain linking.
   * Use this to add cross domain support for links that are added to the DOM
   * dynamically. Learn more about cross domain linking.
   */
  getCrossDomainLinkingUrlParameter() {
    this.push("getCrossDomainLinkingUrlParameter")
  }

  //
  // CONFIGURATION OF THE TRACKER OBJECT
  //

  /**
   * Override document.title
   */
  setDocumentTitle(title: string) {
    this.push("setDocumentTitle", title)
  }

  /**
   * Set array of hostnames or domains to be treated as local. For wildcard
   * subdomains, you can use: setDomains('.example.com'); or
   * setDomains('*.example.com');. You can also specify a path along a domain:
   * setDomains('*.example.com/subsite1');
   */
  setDomains(domains: string[]) {
    this.push("setDomains", domains)
  }

  /**
   * Override the page's reported URL
   */
  setCustomUrl(url: string) {
    this.push("setCustomUrl", url)
  }

  /**
   * Override the detected Http-Referer
   */
  setReferrerUrl(url: string) {
    this.push("setReferrerUrl", url)
  }

  /**
   * Specify the website ID.
   * Redundant: can be specified in getTracker() constructor.
   */
  setSiteId(id: number) {
    this.push("setSiteId", id)
  }

  /**
   * Specify the Matomo HTTP API URL endpoint. Points to the root directory of piwik,
   * e.g. https://piwik.example.org/ or https://example.org/piwik/.
   *
   * This function is only useful when the 'Overlay' report is not working.
   * By default, you do not need to use this function.
   */
  setApiUrl(url: string) {
    this.push("setApiUrl", url)
  }

  /**
   * Specify the Matomo server URL. Redundant: can be specified in getTracker()
   * constructor.
   */
  setTrackerUrl(url: string) {
    this.push("setTrackerUrl", url)
  }

  /**
   * Return the Matomo server URL.
   */
  getPiwikUrl() {
    this.push("getPiwikUrl")
  }

  /**
   * Return the current url of the page that is currently being visited.
   * If a custom URL was set before calling this method, the custom URL will be returned.
   */
  getCurrentUrl() {
    this.push("getCurrentUrl")
  }

  /**
   * Set classes to be treated as downloads (in addition to piwik_download)
   */
  setDownloadClasses(classes: string | string[]) {
    this.push("setDownloadClasses", classes)
  }

  /**
   * Set list of file extensions to be recognized as downloads.
   * Example: 'doc' or ['doc', 'xls']
   */
  setDownloadExtensions(extensions: string | string[]) {
    this.push("setDownloadExtensions", extensions)
  }

  /**
   * Specify additional file extensions to be recognized as downloads.
   * Example: 'doc' or ['doc', 'xls']
   */
  addDownloadExtensions(extensions: string | string[]) {
    this.push("addDownloadExtensions", extensions)
  }

  /**
   * Specify file extensions to be removed from the list of download file extensions.
   * Example: 'doc' or ['doc', 'xls']
   */
  removeDownloadExtensions(extensions: string | string[]) {
    this.push("removeDownloadExtensions", extensions)
  }

  /**
   * Set classes to be ignored if present in link (in addition to piwik_ignore)
   */
  setIgnoreClasses(classes: string | string[]) {
    this.push("setIgnoreClasses", classes)
  }

  /**
   * Set classes to be treated as outlinks (in addition to piwik_link)
   */
  setLinkClasses(classes: string | string[]) {
    this.push("setLinkClasses", classes)
  }

  /**
   * Set delay for link tracking in milliseconds.
   */
  setLinkTrackingTimer(milliseconds: number) {
    this.push("setLinkTrackingTimer", milliseconds)
  }

  /**
   * Get delay for link tracking (in milliseconds).
   */
  getLinkTrackingTimer() {
    this.push("getLinkTrackingTimer")
  }

  /**
   * Set to true to not record the hash tag (anchor) portion of URLs
   */
  discardHashTag(discard: boolean) {
    this.push("discardHashTag", discard)
  }

  /**
   * Append a custom string to the end of the HTTP request to piwik.php?
   */
  appendToTrackingUrl(appendToUrl) {
    this.push("appendToTrackingUrl", appendToUrl)
  }

  /**
   * Set to true to not track users who opt out of tracking using Mozilla's
   * (proposed) Do Not Track setting.
   */
  setDoNotTrack(enabled: boolean) {
    this.push("setDoNotTrack", enabled)
  }

  /**
   * Enable a frame-buster to prevent the tracked web page from being framed/iframed.
   */
  killFrame() {
    this.push("killFrame")
  }

  /**
   * Force the browser load the live URL if the tracked web page is loaded from a
   * local file (e.g., saved to someone's desktop).
   */
  redirectFile(url: string) {
    this.push("redirectFile", url)
  }

  /**
   * Record how long the page has been viewed if the minimumVisitLength (in seconds)
   * is attained; the heartBeatDelay determines how frequently to update the server
   */
  setHeartBeatTimer(minimumVisitLength: number, heartBeatDelay: number) {
    this.push("setHeartBeatTimer", minimumVisitLength, heartBeatDelay)
  }

  /**
   * Return the 16 characters ID for the visitor
   */
  getVisitorId() {
    this.push("getVisitorId")
  }

  /**
   * Return the visitor cookie contents in an array
   */
  getVisitorInfo() {
    this.push("getVisitorInfo")
  }

  /**
   * Return the visitor attribution array (Referer information and / or Campaign
   * name & keyword). Attribution information is used by Matomo to credit the
   * correct referrer (first or last referrer) used when a user triggers a goal
   * conversion.
   *
   * You can also use any of the following functions to get specific attributes of
   * data:
   *
   * - `piwikTracker.getAttributionCampaignName()`
   * - `piwikTracker.getAttributionCampaignKeyword()`
   * - `piwikTracker.getAttributionReferrerTimestamp()`
   * - `piwikTracker.getAttributionReferrerUrl()`
   */
  getAttributionInfo() {
    this.push("getAttributionInfo")
  }

  /**
   * Return the User ID string if it was set.
   */
  getUserId() {
    this.push("getUserId")
  }

  /**
   * Sets a User ID to this user (such as an email address or a username).
   */
  setUserId(userId: string) {
    this.push("setUserId", userId)
  }

  /**
   * Clears (un-set) the User ID.
   */
  resetUserId() {
    this.push("resetUserId")
  }

  /**
   * Set a custom variable.
   */
  setCustomVariable(index: number, name: string, value: any, scope: "visit" | "page") {
    this.push("setCustomVariable", index, name, value, scope)
  }

  /**
   * Delete a custom variable.
   */
  deleteCustomVariable(index: number, scope: "visit" | "page") {
    this.push("deleteCustomVariable", index, scope)
  }

  /**
   * Retrieve a custom variable.
   */
  getCustomVariable(index: number, scope: "visit" | "page") {
    this.push("getCustomVariable", index, scope)
  }

  /**
   * When called then the Custom Variables of scope "visit" will be stored
   * (persisted) in a first party cookie for the duration of the visit.
   * This is useful if you want to call getCustomVariable later in the visit.
   * (by default custom variables are not stored on the visitor's computer.)
   */
  storeCustomVariablesInCookie() {
    this.push("storeCustomVariablesInCookie")
  }

  /**
   * Set a custom dimension. (requires Custom Dimensions plugin)
   */
  setCustomDimension(customDimensionId: number, customDimensionValue: string) {
    this.push("setCustomDimension", customDimensionId, customDimensionValue)
  }

  /**
   * Delete a custom dimension. (requires Custom Dimensions plugin)
   */
  deleteCustomDimension(customDimensionId: number) {
    this.push("deleteCustomDimension", customDimensionId)
  }

  /**
   * Retrieve a custom dimension. (requires Custom Dimensions plugin)
   */
  getCustomDimension(customDimensionId: number) {
    this.push("getCustomDimension", customDimensionId)
  }

  /**
   * Set campaign name parameter(s).
   * (Help: Customize Campaign name parameter names)
   */
  setCampaignNameKey(name: string) {
    this.push("setCampaignNameKey", name)
  }

  /**
   * Set campaign keyword parameter(s).
   * (Help: Customize Campaign keyword parameter names)
   */
  setCampaignKeywordKey(keyword: string) {
    this.push("setCampaignKeywordKey", keyword)
  }

  /**
   * Set to true to attribute a conversion to the first referrer.
   * By default, conversion is attributed to the most recent referrer.
   */
  setConversionAttributionFirstReferrer(enabled: boolean) {
    this.push("setConversionAttributionFirstReferrer", enabled)
  }

  //
  // MANAGING CONSENT
  //
  // Matomo provides a mechanism to manage your user's consent.
  //
  // You can require that users consent before you track any of their actions,
  // disable tracking for users that do not consent, and re-enable tracking for
  // those that consent later.
  //

  /**
   * By default the Matomo tracker assumes consent to tracking.
   *
   * To change this behavior so nothing is tracked until a user consents, you
   * must call requireConsent.
   */
  requireConsent() {
    this.push("requireConsent")
  }

  /**
   * Mark that the current user has consented.
   *
   * The consent is one-time only, so in a subsequent browser session, the user
   * will have to consent again. To remember consent, see the method below:
   * rememberConsentGiven.
   */
  setConsentGiven() {
    this.push("setConsentGiven")
  }

  /**
   * Mark that the current user has consented, and remembers this consent through
   * a browser cookie. The next time the user visits the site, Matomo will remember
   * that they consented, and track them.
   *
   * If you call this method, you do not need to call setConsentGiven.
   */
  rememberConsentGiven(hoursToExpire: number) {
    this.push("rememberConsentGiven", hoursToExpire)
  }

  /**
   * Remove a user's consent, both if the consent was one-time only and if the
   * consent was remembered.
   *
   * After calling this method, the user will have to consent again in order to
   * be tracked.
   *
   * You can use these methods to build your own consent form/pages.
   * Learn more about asking for consent.
   */
  forgetConsentGiven() {
    this.push("forgetConsentGiven")
  }

  //
  // CONFIGURATION OF TRACKING COOKIES
  //
  // Matomo uses first party cookies to keep track of some user information over
  // time. Consideration must be given to retention times and avoiding conflicts
  // with other cookies, trackers, and apps.
  //

  /**
   * Disable all first party cookies. Existing Matomo cookies for this websites
   * will be deleted on the next page view.
   */
  disableCookies() {
    this.push("disableCookies")
  }

  /**
   * Delete the tracking cookies currently currently set
   * (this is useful when creating new visits)
   */
  deleteCookies() {
    this.push("deleteCookies")
  }

  /**
   * Return whether cookies are enabled and supported by this browser.
   */
  hasCookies() {
    this.push("hasCookies")
  }

  /**
   * the default prefix is 'pk'.
   */
  setCookieNamePrefix(prefix: string) {
    this.push("setCookieNamePrefix", prefix)
  }

  /**
   * the default is the document domain; if your website can be visited at both
   * www.example.com and example.com, you would use:
   *
   * tracker.setCookieDomain('.example.com'); or
   * tracker.setCookieDomain('*.example.com');
   */
  setCookieDomain(domain: string) {
    this.push("setCookieDomain", domain)
  }

  /**
   * the default is '/'.
   */
  setCookiePath(path: string) {
    this.push("setCookiePath", path)
  }

  /**
   * set to true to enable the Secure cookie flag on all first party cookies.
   *
   * This should be used when your website is only available under HTTPS so that
   * all tracking cookies are always sent over secure connection.
   */
  setSecureCookie(enabled: boolean) {
    this.push("setSecureCookie", enabled)
  }

  /**
   * the default is 13 months
   */
  setVisitorCookieTimeout(seconds: number) {
    this.push("setVisitorCookieTimeout", seconds)
  }

  /**
   * the default is 6 months
   */
  setReferralCookieTimeout(seconds: number) {
    this.push("setReferralCookieTimeout", seconds)
  }

  /**
   * the default is 30 minutes
   */
  setSessionCookieTimeout(seconds: number) {
    this.push("setSessionCookieTimeout", seconds)
  }

  /**
   * When tracking visitor actions in JavaScript on your website, it is possible
   * to enable the use of the `navigator.sendBeacon()` method which Matomo will
   * use to asynchronously transfer the tracking requests over HTTPs to the Tracking API
   */
  alwaysUseSendBeacon() {
    this.push("alwaysUseSendBeacon")
  }

  //
  // ADVANCED USES
  //

  /**
   * Add click listener to a specific link element. When clicked, Matomo will
   * log the click automatically.
   */
  addListener(element: Element) {
    this.push("addListener", element)
  }

  /**
   * Set the request method to either "GET" or "POST". (The default is "GET".)
   *
   * To use the POST request method, either 1) the Matomo host is the same as
   * the tracked website host (Matomo installed in the same domain as your
   * tracked website), or 2) if Matomo is not installed on the same host as your
   * website, you need to enable CORS (Cross domain requests) as explained in
   * this FAQ.
   *
   */
  setRequestMethod(method: "GET" | "POST") {
    this.push("setRequestMethod", method)
  }

  /**
   * Set a function that will process the request content.
   *
   * The function will be called once the request (query parameters string) has
   * been prepared, and before the request content is sent.
   */
  setCustomRequestProcessing(fn: any) {
    this.push("setCustomRequestProcessing", fn)
  }

  /**
   * Set request Content-Type header value. Applicable when "POST" request method
   * is used via setRequestMethod.
   */
  setRequestContentType(contentType: string) {
    this.push("setRequestContentType", contentType)
  }

  /**
   * Disable the feature which groups together multiple tracking requests and
   * send them as a bulk POST request.
   *
   * Disabling this feature is useful when you want to be able to replay all logs:
   * one must use disableQueueRequest to disable this behaviour to later be able
   * to replay logged Matomo logs (otherwise a subset of the requests wouldn't
   * be able to be replayed).
   */
  disableQueueRequest() {
    this.push("disableQueueRequest")
  }
}
