import { NgModule } from "@angular/core"
import { RouterModule, Routes } from "@angular/router"

import { AfbPageComponent } from "./afb-page/afb-page.component"
import { NotFoundComponent } from "./core/not-found/not-found.component"
import { ServerErrorComponent } from "./core/server-error/server-error.component"
import { LandingComponent } from "./landing/landing.component"

const routes: Routes = [
  { path: "", component: LandingComponent },
  // { path: "impressum", component: ImpressumComponent },
  { path: "afb", component: AfbPageComponent },
  { path: "404", component: NotFoundComponent },
  { path: "500", component: ServerErrorComponent },
  { path: "**", redirectTo: "404" },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
