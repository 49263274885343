import { Component, OnInit, Input } from "@angular/core"

@Component({
  selector: "app-afb",
  templateUrl: "./afb.component.html",
  styles: [
    `
      li {
        color: black;
      }
    `,
  ],
})
export class AfbComponent implements OnInit {
  @Input() isOlderThan18: boolean
  constructor() {}

  ngOnInit() {}
}
