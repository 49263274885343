import { AfterViewInit, Directive, ElementRef, Input, OnChanges } from "@angular/core"

import { TrackingService } from "./tracking.service"

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "a,button,input,textarea",
})
export class TrackElementDirective implements AfterViewInit, OnChanges {
  @Input() crLabel: string
  @Input() crIs: string

  private label: string

  constructor(private readonly el: ElementRef, private readonly tracking: TrackingService) {}

  tagElement() {
    const el: HTMLElement = this.el.nativeElement
    const label = [this.crIs, this.crLabel || el.textContent?.trim()].filter(Boolean).join(": ").trim()

    if (label === this.label) {
      return
    }
    this.label = label

    if (label) {
      this.tracking.tagElement(el, label)
      el.setAttribute("aria-label", label)
    } else {
      console.debug("💥 Element has no label:", el)
    }
  }

  ngAfterViewInit() {
    this.tagElement()
  }

  ngOnChanges() {
    this.tagElement()
  }
}
