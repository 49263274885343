import { Component, OnInit } from "@angular/core"
import { FeaturesService } from "../shared/features.service"

@Component({
  selector: "app-infos",
  templateUrl: "./infos.component.html",
  styleUrls: ["./infos.component.scss"],
})
export class InfosComponent implements OnInit {
  features: OverviewFeature[]
  constructor(private featureService: FeaturesService) {}
  ngOnInit() {
    this.featureService.getFeatures().subscribe((features) => (this.features = features))
  }
}
