import { Injectable } from "@angular/core"
import { interval } from "rxjs"

@Injectable({
  providedIn: "root",
})
export class CountdownService {
  public goalDate = new Date(2022, 8, 1)

  public timeLeft: Time = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    total: 0,
  }

  constructor() {
    this.timeLeft.total = this.differenceToToday()
    this.calculateTimeLeft()
    interval(1000).subscribe(() => {
      this.timeLeft.total -= 1
      this.calculateTimeLeft()
    })
  }

  private differenceToToday(): number {
    return Math.floor((this.goalDate.getTime() - new Date().getTime()) / 1000)
  }

  private calculateTimeLeft() {
    let totalSeconds = this.timeLeft.total
    this.timeLeft.days = Math.floor(totalSeconds / 60 / 60 / 24)
    totalSeconds -= this.timeLeft.days * 60 * 60 * 24

    this.timeLeft.hours = Math.floor(totalSeconds / 60 / 60)
    totalSeconds -= this.timeLeft.hours * 60 * 60

    this.timeLeft.minutes = Math.floor(totalSeconds / 60) % 60
    totalSeconds -= this.timeLeft.minutes * 60

    this.timeLeft.seconds = totalSeconds % 60
  }
}
