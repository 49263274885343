<div style="background-color: #e9ecef">
  <div class="container p-5">
    <div class="row align-items-center">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-md-6 mt-5" *ngFor="let feature of features">
            <div class="info justify-content-center text-center">
              <div class="pr-md-5">
                <div class="icon icon-shape icon-shape-primary shadow rounded-circle mb-4">
                  <i class="fas fa-{{ feature.icon }}"></i>
                </div>
                <h5 class="display-5">{{ feature.title }}</h5>
                <p>{{ feature.subtitle }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
