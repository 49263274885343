<div
  class="col-md-10 mx-auto p-3 bg-primary text-white"
  style="border-radius: 25px; font-size: 1.05em; line-height: 1.4; font-weight: lighter"
>
  <h3 class="display-4 text-white">Allgemeine Freizeitbedingungen (AFB):</h3>
  Die Freizeit wird von der Christlichen Versammlung Schwabach (Verein für Diakonie und Mission) veranstaltet. <br />
  Wir bitten Euch: Nehmt keinen <strong>Alkohol, Zigaretten, Drogen oder Waffen</strong> mit. <br />
  <strong>Jeder</strong> hat es ein bisschen selber in der Hand wie schön oder nicht schön diese Zeit wird.<br />

  <div *ngIf="!isOlderThan18">
    <h4 class="display-4 mt-4 text-white">Für unter 18 Jährige</h4>
    <ul class="list-group">
      <li class="list-group-item mt-2">
        Mir bzw. meinen Eltern ist bekannt, dass unter Umständen stundenweise freie Zeit gegeben wird und dass während
        dieser Zeit keine Aufsichtspflicht übernommen werden kann.
      </li>
      <li class="list-group-item mt-2">
        Mir bzw. meinen Eltern ist bekannt, dass während des Freizeitaufenthaltes Genussmittel wie Zigaretten, Alkohol
        und Drogen verboten sind. Sobald ich die Freizeitordnung extrem übertrete und mich den Anweisungen der
        Freizeitleitung widersetze, kann keine Verantwortung übernommen werden. In diesem Fall willige ich ein, mein
        Kind vom Freizeitort abzuholen.
      </li>
    </ul>
  </div>

  <h4 class="display-4 mt-4 text-white">Zahlung</h4>
  Deine Anmeldung ist gültig mit der Überweisung der Anzahlung auf das Konto von Tim (Zahlungsinformationen bekommst du
  per Mail, nach deiner Anmeldung). Die Überweisung <strong>muss</strong> innerhalb von 14 Tagen nach der Anmeldung
  getätigt worden sein. Um Tim das Leben zu erleichtern bitte <strong>keine</strong> Barzahlung. Alles per Überweisung.
  Unten noch diese und weitere Infos zusammengefasst
  <ul class="mt-2 list-group">
    <li class="list-group-item mt-2">
      Anmeldung ist gültig, wenn Anzahlung innerhalb von 14 Tagen auf Tims Konto überwiesen wurde.
    </li>
    <li class="list-group-item mt-2">Keine Barzahlung möglich</li>
    <li class="list-group-item mt-2">
      Zahlungsinformationen findest du nach deiner Anmeldung in der Mail. Wenn du keine Mail bekommst schau bitte in
      deinem Spam Ordner nach.
    </li>
    <li class="list-group-item mt-2">Du kannst auch alles auf einmal zahlen.</li>
    <li class="list-group-item mt-2">
      Deine Überweisung sollte folgende Information enthalten (als Referenz). <br />
      Schweden 2020 - Name, Vorname - 1./2. Anzahlung bzw. Gesamt
    </li>
  </ul>

  <h4 class="display-4 mt-4 text-white">Reiserücktritt:</h4>
  Die Anmeldung ist <strong>verbindlich</strong>. Wer abspringt, muss für einen Ersatzteilnehmer sorgen! Ist dies nicht
  möglich gelten folgende Regelungen:
  <ul class="mt-2 list-group">
    <li class="list-group-item mt-2">Bei Stornierung bis zum 31.06.2020 wird die Anzahlung einbehalten</li>
    <li class="list-group-item mt-2">
      Bei Stornierung ab dem 01.07.2020 werden 60 % des Freizeitbetrages einbehalten.
    </li>
  </ul>
</div>
