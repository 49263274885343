import { DOCUMENT } from "@angular/common"
import { Component, HostListener, Inject } from "@angular/core"
import { PageScrollService } from "ngx-page-scroll-core"

import { CountdownService } from "./shared/countdown.service"
import { TrackingService } from "./tracking/tracking.service"

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styles: [
    `
      .btn.back-to-top.show {
        opacity: 1;
        visibility: visible;
      }

      .btn.back-to-top {
        display: inline-block;
        width: 40px;
        height: 40px;
        text-align: center;
        border-radius: 6px;
        position: fixed;
        bottom: 30px;
        right: 30px;
        transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
        opacity: 0;
        visibility: hidden;
        z-index: 1000;
      }
    `,
  ],
})
export class AppComponent {
  percentage = 0

  constructor(
    private countdownService: CountdownService,
    private pageScrollService: PageScrollService,
    tracking: TrackingService, // inject to trigger execution
    @Inject(DOCUMENT) private document: any,
  ) {
    window.EBWidgets.createWidget({
      widgetType: "checkout",
      eventId: "330428409267",
      modal: true,
      modalTriggerElementId: "eventbrite-widget-modal-trigger-330428409267",
    })
  }

  countdownActive() {
    if (this.countdownService.timeLeft.total < 0) {
      return false
    }
    return this.countdownService.timeLeft.total < 0
    // return environment.production
  }

  scrollToTop() {
    this.pageScrollService.scroll({
      document: this.document,
      scrollTarget: "#top",
      scrollOffset: 0,
    })
  }

  @HostListener("window:scroll", [])
  checkScroll() {
    const h = document.documentElement
    const b = document.body
    const st = "scrollTop"
    const sh = "scrollHeight"

    this.percentage = ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100
  }
}
