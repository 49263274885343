import { Injectable } from "@angular/core"
import { Observable, of } from "rxjs"

@Injectable({
  providedIn: "root",
})
export class PricingService {
  private included = ["Unbegrenztes Essen", "Inklusive Anfahrt", "Nachts im Haus schlafen", "SAUNA!"]

  getPricing(): Observable<Pricing[]> {
    return of([])
  }

  getIncluded(): Observable<string[]> {
    return of(this.included)
  }
}
