<footer class="footer">
  <div class="container">
    <div class="row row-grid align-items-center mb-3">
      <div class="col-12">
        <h5 class="text-primary font-weight-light mb-2">Jugend der christlichen Versammlung Schwabach</h5>
        <h4 class="mb-0 font-weight-light">
          Hier gibt es weitere Infos zur
          <a href="http://www.cv-schwabach.de" target="_blank" class="text-primary"> Gemeinde</a>.
        </h4>
      </div>
    </div>
    <hr />
    <div class="row align-items-center justify-content-md-between">
      <div class="col-md-6">
        <div class="copyright">
          © 2022
          <a href="https://roser.dev" target="_blank"> Jonas Roser </a>
        </div>
      </div>
      <div class="col-md-6">
        <ul class="nav nav-footer justify-content-end">
          <li class="nav-item">
            <a class="nav-link" href="http://www.cv-schwabach.de" target="_blank"> CV Schwabach </a>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link" [routerLink]="'/impressum'"> Impressum </a>
          </li> -->
        </ul>
      </div>
    </div>
  </div>
</footer>
