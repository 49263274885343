<div class="container p-5">
  <div class="row">
    <div class="col-md-8 text-center mx-auto">
      <h3 class="display-2">Jugendfreizeit 2022</h3>
      <p class="lead">
        Du bist in der Jugend oder wurdest eingeladen? Du hast Lust 10 Tage in Österreich zu verbringen und Gottes Wort
        besser kennenzulernen?
      </p>
      <p class="lead">Dann bist du hier genau richtig!</p>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-md-6 mx-auto" *ngIf="shortOverview">
      <h5 *ngFor="let shortFeatures of shortOverview.features" class="mt-3 mb-3 row">
        <span class="col-12">
          <i class="fas fa-{{ shortFeatures.icon }} mr-3"></i>
          {{ shortFeatures.content }}
        </span>
      </h5>
    </div>
  </div>
  <!-- <div class="row mt-3">
    <div class="col-md-6 mx-auto">
      <p>(Abfahrt von Schwabach Donnerstagabend (20.08.2020). Ankunft Mittwoch Nacht (02.09.2020).</p>
    </div>
  </div> -->
</div>
