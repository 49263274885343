import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { RouterModule } from "@angular/router"
import { YouTubePlayerModule } from "@angular/youtube-player"
import { CollapseModule } from "ngx-bootstrap/collapse"
import { BsDropdownModule } from "ngx-bootstrap/dropdown"
import { NgxPageScrollModule } from "ngx-page-scroll"

import { TrackingModule } from "../tracking/tracking.module"
import { FooterComponent } from "./footer/footer.component"
import { GoBackHeaderComponent } from "./go-back-header/go-back-header.component"
import { HeaderComponent } from "./header/header.component"
import { NotFoundComponent } from "./not-found/not-found.component"
import { ServerErrorComponent } from "./server-error/server-error.component"

// Components
const components = [HeaderComponent, FooterComponent, ServerErrorComponent, NotFoundComponent, GoBackHeaderComponent]

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [
    CommonModule,
    NgxPageScrollModule,
    RouterModule,
    CollapseModule,
    BsDropdownModule,
    YouTubePlayerModule,
    TrackingModule,
  ],
})
export class CoreModule {}
