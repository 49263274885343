import { Injectable } from "@angular/core"
import { Observable, of } from "rxjs"

@Injectable({
  providedIn: "root",
})
export class FeaturesService {
  private shortOverview: OverviewShort = {
    title: "Übersicht",
    features: [
      { content: "ca. 500€ (150€ Anzahlung)", icon: "euro-sign" },
      { content: "vom 27.08.2022 - 06.09.2022", icon: "calendar" },
      { content: "im Jugendhotel Schloßhof Österreichs", icon: "map-marker" },
    ],
  }
  private features: OverviewFeature[] = [
    {
      title: "Gottes Wort",
      subtitle: "Predigten, Andachten, Gebet",
      icon: "cross",
      features: ["Feature1", "Feature2", "Feature3"],
    },
    {
      title: "Gemeinschaft",
      subtitle: "Balliet Julia",
      icon: "user-friends",
      features: ["Feature1", "Feature2", "Feature3"],
    },
    {
      title: "See",
      subtitle: "Schwimmen. Kanufahren...",
      icon: "water",
      features: ["Feature1", "Feature2", "Feature3"],
    },
    {
      title: "Essen",
      subtitle: "Selbstgekocht. Lecker!",
      icon: "fish",
      features: ["Feature1", "Feature2", "Feature3"],
    },
  ]

  constructor() {}

  getShortOverview(): Observable<OverviewShort> {
    return of(this.shortOverview)
  }

  getFeatures(): Observable<OverviewFeature[]> {
    return of(this.features)
  }
}
